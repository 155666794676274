exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-requestor-js": () => import("./../../../src/pages/new-requestor.js" /* webpackChunkName: "component---src-pages-new-requestor-js" */),
  "component---src-pages-open-js": () => import("./../../../src/pages/open.js" /* webpackChunkName: "component---src-pages-open-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-test-add-js": () => import("./../../../src/pages/test-add.js" /* webpackChunkName: "component---src-pages-test-add-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-accreditation-page-js": () => import("./../../../src/templates/accreditation-page.js" /* webpackChunkName: "component---src-templates-accreditation-page-js" */),
  "component---src-templates-appointments-page-js": () => import("./../../../src/templates/appointments-page.js" /* webpackChunkName: "component---src-templates-appointments-page-js" */),
  "component---src-templates-archive-page-js": () => import("./../../../src/templates/archive-page.js" /* webpackChunkName: "component---src-templates-archive-page-js" */),
  "component---src-templates-career-page-js": () => import("./../../../src/templates/career-page.js" /* webpackChunkName: "component---src-templates-career-page-js" */),
  "component---src-templates-commercial-testing-page-js": () => import("./../../../src/templates/commercial-testing-page.js" /* webpackChunkName: "component---src-templates-commercial-testing-page-js" */),
  "component---src-templates-eorders-page-js": () => import("./../../../src/templates/eorders-page.js" /* webpackChunkName: "component---src-templates-eorders-page-js" */),
  "component---src-templates-hpv-page-js": () => import("./../../../src/templates/hpv-page.js" /* webpackChunkName: "component---src-templates-hpv-page-js" */),
  "component---src-templates-locations-page-js": () => import("./../../../src/templates/locations-page.js" /* webpackChunkName: "component---src-templates-locations-page-js" */),
  "component---src-templates-molecular-page-js": () => import("./../../../src/templates/molecular-page.js" /* webpackChunkName: "component---src-templates-molecular-page-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-patient-requested-page-js": () => import("./../../../src/templates/patient-requested-page.js" /* webpackChunkName: "component---src-templates-patient-requested-page-js" */),
  "component---src-templates-patients-page-js": () => import("./../../../src/templates/patients-page.js" /* webpackChunkName: "component---src-templates-patients-page-js" */),
  "component---src-templates-payment-page-js": () => import("./../../../src/templates/payment-page.js" /* webpackChunkName: "component---src-templates-payment-page-js" */),
  "component---src-templates-people-page-js": () => import("./../../../src/templates/people-page.js" /* webpackChunkName: "component---src-templates-people-page-js" */),
  "component---src-templates-prepare-page-js": () => import("./../../../src/templates/prepare-page.js" /* webpackChunkName: "component---src-templates-prepare-page-js" */),
  "component---src-templates-providers-page-js": () => import("./../../../src/templates/providers-page.js" /* webpackChunkName: "component---src-templates-providers-page-js" */),
  "component---src-templates-research-page-js": () => import("./../../../src/templates/research-page.js" /* webpackChunkName: "component---src-templates-research-page-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-supplies-page-js": () => import("./../../../src/templates/supplies-page.js" /* webpackChunkName: "component---src-templates-supplies-page-js" */),
  "component---src-templates-transfusion-page-js": () => import("./../../../src/templates/transfusion-page.js" /* webpackChunkName: "component---src-templates-transfusion-page-js" */)
}

